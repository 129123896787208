<template>
  <comments-module-component />
</template>

<script>
import CommentsModuleComponent from "@/components/admin/CommentsModuleComponent";

export default {
  name: "CommentsModule",
  title: "Gestión de Comentarios | Baja California Health Tourism",
  components: { CommentsModuleComponent },
};
</script>

<style scoped></style>
