<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="comments"
        :items-per-page="-1"
        :search="searchComment"
        :loading="loadingData"
        :expanded="expanded"
        single-expand
        show-expand
        item-key="id"
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: false,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title>Gestión de Comentarios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-comment"
                label="Buscar.."
                name="findcomment"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchComment"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-responsive>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline"
                  >Esta seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Proceder</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogApproved" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  Desea aprobar este comentario?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeApprove">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="approveItemConfirm">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.approved="{ item }">
          <v-simple-checkbox
            v-model="item.approved"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="doApproveItem(item)"
              >
                mdi-comment-check
              </v-icon>
            </template>
            <span>Aprobar Comentario?</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getComments"> Recargar </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid class="fill-height">
              <v-row dense justify="start" align="start" class="ma-0">
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Nombres y Apellido: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      {{ item.name }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Correo Electrónico: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      {{ item.email }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card flat class="pt-0">
                    <v-card-title class="pb-0">
                      <span>Comentario escrito: </span>
                    </v-card-title>
                    <v-card-text class="pt-0 mt-0">
                      {{ item.comment }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import commentsService from "@/providers/CommentsService";

export default {
  name: "CommentsModuleComponent",
  data: () => ({
    snackBar: false,
    snackText: "",
    searchComment: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    dialogApproved: false,
    comments: [],
    expanded: [],
    headers: [
      { text: "", value: "data-table-expand", width: 5 },
      { text: "Id", value: "id", align: " d-none" }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "E-mail", value: "email" },
      { text: "Aprobado", value: "approved", align: "center", width: 100 },
      {
        text: "Acciones",
        value: "actions",
        width: 20,
        align: "center",
        sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      email: "",
      comment: "",
      approved: false,
      created_at: null,
    },
    defaultItem: {
      id: 0,
      name: "",
      email: "",
      comment: "",
      approved: false,
      created_at: null,
    },
    nameRules: [
      (v) => !!v || "El Nombre es obligatorio!",
      // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva ciudad" : "Editar ciudad";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogApproved(val) {
      val || this.closeApproved();
    },
  },
  methods: {
    getComments() {
      // carga los datos desde el proveedor de datos.
      this.loadingData = true;
      commentsService.getAllRecords().then((record) => {
        this.comments = record.value;
        // console.log("Comentarios: ", this.comments);
        this.loadingData = false;
      });
    },
    editItem(item) {
      this.editedIndex = this.comments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.comments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.comments.splice(this.editedIndex, 1);
      commentsService.deleteRecord(this.editedItem.id);
      // console.log('Item deleted:', this.editedItem);
      this.closeDelete();
      this.snackText = "Registro eliminado con éxito.";
      this.snackBar = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    doApproveItem(item) {
      // console.log('Logica para aprobar ae comentario...', item);
      this.editedItem = Object.assign({}, item);
      this.dialogApproved = true;
    },
    approveItemConfirm() {
      this.editedItem.approved = true;
      commentsService.approveComment(this.editedItem).then((result) => {
        this.closeApprove();
        if (result.success) {
          this.snackText = "Comentario en proceso de aprobación!";
        } else {
          this.snackText = "Un error impidió procesar este comentario!";
        }
        this.snackBar = true;
        this.getComments();
      });
    },
    closeApprove() {
      this.dialogApproved = false;
    },
  },
  mounted() {
    this.comments = [];
    this.getComments();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
